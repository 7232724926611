import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import BlockContent from '../components/block-content'
import Seo from '../components/seo'
import SocialList from '../components/socialList'
import { responsiveTitle1 } from '../components/typography.module.css'
import Layout from '../containers/layout'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import styles from './../styles/contact.module.css'

const ContactPage = ({ data, errors }) => {
  const { page, person } = data

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  const imageUrl = imageUrlFor(buildImageObj(person.image))
    .width(200)
    .height(200)
    .fit('crop')
    .url()

  return (
    <Layout>
      <Seo title={page.title} />
      <Helmet>
        <link rel='preload' as='image' href={imageUrl} importance='high' />
      </Helmet>
      <h1 className={responsiveTitle1}>{page.title}</h1>
      <div className={styles.profileWrapper}>
        <div className={styles.avatar}>
          <img
            src={imageUrl}
            alt="Leonidas' profile picture"
          />
        </div>
        <BlockContent blocks={page._rawBody || []} />
      </div>
      <h2 className={styles.socialHeading}>Get social with me</h2>
      <SocialList />
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default function ContactPageQuery () {
  return (
    <StaticQuery
      query={graphql`
      query ContactPageQuery {
        page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
          title
          _rawBody
        }
        person: sanityPerson(name: {regex: "/Leonidas/"}) {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    `}
      render={data => <ContactPage data={data} />}
    />
  )
}
